<template>
  <div class="container">
    <service-edit-form
        payload-process="edit"
        :detailed-services-group="detailedServicesGroup"
    />
  </div>
</template>

<script>
import ServiceEditForm from "@/components/baseServices/serviceEditForm";
import {mapGetters} from "vuex";
export default {
  name: "ServiceGroupEdit",
  components: {ServiceEditForm},
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters('service_groups', ['detailedServicesGroup'])
  },
  methods: {
    loadDetailedServiceGroup() {
      this.isLoading = true;
      this.$store.dispatch('service_groups/getServiceGroup', this.$route.params.id).then(() => {
        this.isLoading = false;
      })
    },
  },
  mounted() {
    this.loadDetailedServiceGroup();
  }
}
</script>

<style scoped>

</style>